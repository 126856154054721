import { ref, computed } from "vue";

export default function useQueryParam(location) {
  const queryParamData = ref({
    redirectUrl: window.location.pathname,
    analyticsLocation: location
  });

  const queryParamsEncoded = computed(() => {
    return `?state=${btoa(JSON.stringify(queryParamData.value))}`;
  });

  return { queryParamData, queryParamsEncoded };
}
