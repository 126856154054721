<template>
  <div class="ml-auto flex items-center">
    <account-dropdown v-if="currentUser" />
    <login-sign-up-button
      v-else
      class="mr-3" />
    <teleport to="#end-of-body">
      <fade-transition>
        <login-register-modal v-if="loginModalShowing" />
      </fade-transition>
    </teleport>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { watch } from "vue";

import LoginSignUpButton from "@/app/javascript/components/nav/LoginSignUpButton.vue";
import useUserNavStore from "../../stores/user-nav-store";
import AccountDropdown from "./AccountDropdown.vue";
import LoginRegisterModal from "../shared/LoginRegisterModal.vue";
import FadeTransition from "../transitions/FadeTransition.vue";

const userNavStore = useUserNavStore();

const { currentUser, loginModalShowing } = storeToRefs(userNavStore);

watch(loginModalShowing, newVal => {
  if (!newVal) {
    window.localStorage.removeItem("addingSample");
  }
});
</script>
