<template>
  <div class="p-4 md:pt-8 w-full self-end">
    <h2 class="typeset-2 text-center mb-5">
      {{ t("register-modal.title") }}
    </h2>
    <social-login
      :prompt-text="t('login-modal.register-with')"
      register-panel
      class="mb-5"
      :o-auth-providers="oAuthProviders"
      :query-params="queryParamsEncoded" />
    <div class="flex w-full items-center pb-5">
      <div class="h-px bg-ink-2 w-full"></div>
      <p class="typeset-4 whitespace-no-wrap mx-3 text-ink-4">
        {{ t("login-modal.with-email") }}
      </p>
      <div class="h-px bg-ink-2 w-full"></div>
    </div>
    <manual-register-form
      signup-location="register-modal"
      :user="user"
      @submit-register-form="submitRegisterForm" />
  </div>
</template>

<script setup>
import { inject, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import validDateOfBirth from "@/app/javascript/utils/validDateOfBirth";
import useUserNavStore from "../../stores/user-nav-store";
import useUserStore from "../../stores/user-store";
import SocialLogin from "./SocialLogin.vue";
import useQueryParams from "../../composables/useQueryParams";
import ManualRegisterForm from "./ManualRegisterForm.vue";

const t = inject("t");
const userNavStore = useUserNavStore();
const userStore = useUserStore();

const { autoOptIn, oAuthProviders } = storeToRefs(userNavStore);
const { registerErrors } = storeToRefs(userStore);
const { register } = userStore;

const props = defineProps({
  weddingAddOns: {
    type: Boolean,
    default: false
  }
});

const { queryParamsEncoded } = useQueryParams("Account Creation Modal");

const user = reactive({
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  newsletter: autoOptIn.value,
  location: "Account Creation Modal",
  weddingAddOns: false
});

watch(
  () => props.weddingAddOns,
  newValue => {
    user.weddingAddOns = newValue;
  }
);

const submitRegisterForm = () => {
  if (user.date_of_birth !== "" && !validDateOfBirth(user.date_of_birth)) {
    registerErrors.value.date_of_birth = [
      t("register-modal.form.date-of-birth-invalid")
    ];
  } else {
    registerErrors.value.date_of_birth = [];
    register(user);
  }
};
</script>
