<template>
  <div class="p-4 w-full self-center">
    <h2
      class="typeset-2 text-center mb-5"
      data-cy="login_modal_title">
      {{ t("login-modal.title") }}
    </h2>
    <form
      class="mb-5"
      @submit.prevent="submitLoginForm">
      <text-input
        v-model="user.email"
        required
        type="email"
        autocorrect="off"
        input-id="login-modal-email"
        :errors="loginErrors.email"
        class="mb-4"
        :label="t('login-modal.form.email')"
        autocapitalize="off"
        autocomplete="off" />
      <text-input
        v-model="user.password"
        required
        input-id="login-modal-password"
        type="password"
        autocorrect="off"
        autocapitalize="off"
        :errors="loginErrors.password"
        class="mb-3"
        :label="t('login-modal.form.password')"
        autocomplete="off" />
      <div
        data-cy="forgotten-your-password"
        class="typeset-7 my-4">
        <a
          :href="newPasswordPath"
          class="underline">
          {{ t("login-modal.form.forgotten-password") }}
        </a>
      </div>
      <input
        type="submit"
        data-cy="login"
        :value="t('login-modal.form.cta')"
        class="btn btn-large btn-primary btn-block mb-3" />
    </form>
    <social-login
      :prompt-text="t('login-modal.login-with')"
      class="mb-7"
      :o-auth-providers="oAuthProviders"
      :query-params="queryParamsEncoded" />
    <div class="flex flex-col items-center">
      <p class="text-center typeset-6">{{ t("login-modal.new-to-papier") }}</p>
      <a
        href="#"
        class="underline typeset-7"
        data-cy="create-an-account-link"
        @click.prevent="accountModalState = 'register'">
        {{ t("login-modal.create-an-account") }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import useUserNavStore from "../../stores/user-nav-store";
import useUserStore from "../../stores/user-store";
import TextInput from "./TextInput.vue";
import SocialLogin from "./SocialLogin.vue";
import useQueryParams from "../../composables/useQueryParams";

const props = defineProps({
  weddingAddOns: {
    type: Boolean,
    default: false
  }
});

const userNavStore = useUserNavStore();
const userStore = useUserStore();
const { login } = userStore;

const user = reactive({
  remember_me: true,
  email: "",
  password: "",
  weddingAddOns: false
});

watch(
  () => props.weddingAddOns,
  newValue => {
    user.weddingAddOns = newValue;
  }
);

const { newPasswordPath, oAuthProviders, accountModalState } =
  storeToRefs(userNavStore);
const { loginErrors } = storeToRefs(userStore);

const { queryParamsEncoded } = useQueryParams("Account Creation Modal");

const submitLoginForm = () => {
  login(user);
};
</script>
