<template>
  <div class="relative">
    <button
      class="btn btn-primary btn-small btn-rounded uppercase"
      data-cy="login-or-signup"
      @mouseenter="showOverlay = true"
      @mouseleave="showOverlay = false"
      @click="showRegisterModal">
      <svg-icon
        name="user"
        class="mr-2"
        width="16px"
        height="16px" />
      <span class="relative top-px">{{ t("login-cta") }}</span>
    </button>
    <div
      class="bg-transparent top-full right-0 absolute pt-2 z-5"
      @mouseenter="showOverlay = true"
      @mouseleave="showOverlay = false">
      <div
        v-if="showOverlay"
        class="bg-paper p-5">
        <div class="flex justify-between items-center mb-5">
          <h3 class="typeset-3">{{ t("login-button-overlay.title") }}</h3>
          <div class="flex gap-x-3 items-center typeset-4">
            <a
              class="cursor-pointer overlay-cta"
              @click="showLoginModal">
              {{ t("login-button-overlay.login") }}
            </a>
            <span>|</span>
            <a
              class="cursor-pointer overlay-cta"
              @click="showRegisterModal">
              {{ t("login-button-overlay.sign-up") }}
            </a>
          </div>
        </div>
        <div class="flex">
          <div
            v-for="perk in perks"
            :key="perk"
            class="text-center p-4">
            <img
              width="110"
              class="aspect-ratio-1x1 mb-3"
              :src="perk.image"
              :srcset="`${perk.imageRetina} 2x`"
              alt="Perk image" />
            <p style="line-height: 20px">{{ t(perk.titleKeyOverlay) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import perks from "@/app/javascript/utils/perksData";

import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import SvgIcon from "../shared/SvgIcon.vue";
import useUserNavStore from "../../stores/user-nav-store";

const userNavStore = useUserNavStore();
const { loginModalShowing, accountModalState } = storeToRefs(userNavStore);

const showOverlay = ref(false);

const showLoginModal = () => {
  loginModalShowing.value = true;
  accountModalState.value = "login";
};

const showRegisterModal = () => {
  loginModalShowing.value = true;
  accountModalState.value = "register";
};

onMounted(() => {
  window.addEventListener("showLoginModal", showLoginModal);
  window.addEventListener("showRegisterModal", showRegisterModal);
});
</script>
<style lang="scss" scoped>
.overlay-cta {
  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1.5px;
  }
}
</style>
