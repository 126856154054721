import { createApp } from "vue";
import useUserNavStore from "@/app/javascript/stores/user-nav-store";
import { createPinia } from "pinia";
import I18n from "../plugins/i18n";
import UserNav from "../components/nav/UserNav.vue";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#user-nav-app");
  if (!node) return;

  const translations = JSON.parse(node.dataset.translations);
  const storeData = JSON.parse(node.dataset.store);

  const pinia = createPinia();
  const app = createApp(UserNav);

  app.use(pinia);
  app.use(I18n, translations);

  const userNavStore = useUserNavStore();

  userNavStore.stores = storeData.stores;
  userNavStore.currentUser = storeData.currentUser;
  userNavStore.showReferAFriend = storeData.showReferAFriend;
  userNavStore.showDwt = storeData.showDwt;
  userNavStore.hideCredit = storeData.hideCredit;
  userNavStore.credit = storeData.credit;
  userNavStore.creditLabel = storeData.creditLabel;
  userNavStore.oAuthProviders = storeData.oAuthProviders;
  userNavStore.adminUser = storeData.adminUser;
  userNavStore.dropdownPaths = storeData.dropdownPaths;
  userNavStore.newPasswordPath = storeData.newPasswordPath;
  userNavStore.autoOptIn = storeData.autoOptIn;

  app.mount(node);
});
