<template>
  <div class="mini-cart relative">
    <mini-cart-dropdown-toggle @toggle="isOpen = !isOpen" />

    <drawer
      :is-open="isOpen"
      @close-drawer="closeDrawer">
      <template #header>
        <h3
          class="typeset-3"
          style="line-height: 32px">
          {{ t("cart.mini-cart.title") }}
        </h3>
      </template>
      <template #content>
        <mini-cart-items @close-drawer="closeDrawer" />
        <product-deal
          v-if="showProductDeal"
          :deal="deal"
          class="flex border-t border-ink-1 pt-5 mt-4 typeset-7 items-center" />
      </template>
      <template #controls>
        <mini-cart-drawer-controls :deal="deal" />
      </template>
    </drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Drawer from "@/app/javascript/components/shared/Drawer.vue";
import ProductDeal from "@/app/javascript/components/shared/ProductDeal.vue";
import MiniCartDrawerControls from "@/app/javascript/components/miniCart/MiniCartDrawerControls.vue";
import MiniCartItems from "../components/miniCart/MiniCartItems.vue";
import MiniCartDropdownToggle from "../components/miniCart/MiniCartDropdownToggle.vue";
import SegmentProductProperties from "../utils/segmentProductProperties";

export default {
  name: "MiniCart",
  components: {
    MiniCartDropdownToggle,
    Drawer,
    MiniCartItems,
    MiniCartDrawerControls,
    ProductDeal
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    ...mapState({
      id: state => state.cart.id,
      items: state => state.cart.items,
      deal: state => state.cart.deal
    }),
    ...mapGetters("cart", ["showProductDealOnCart"]),
    showProductDeal() {
      return this.showProductDealOnCart && this.deal.showOnMiniCart;
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        const products = this.items.map(item =>
          new SegmentProductProperties(item).call()
        );
        analytics.track("Mini Cart Viewed", {
          cart_id: this.id,
          products
        });
      }
    }
  },
  methods: {
    closeDrawer() {
      this.isOpen = false;
    }
  }
};
</script>
