<template>
  <modal
    data-cs-capture
    :open="loginModalShowing"
    size="large"
    header-class="bg-paper-3 md:absolute md:top-0 md:right-0 h-8"
    class="account-modal"
    @closed="loginModalShowing = false">
    <template #header></template>
    <template #body>
      <div class="md:flex">
        <div class="md:w-1/2 md:pt-8 px-4 pb-2 md:pb-5 flex flex-col md:gap-7">
          <perks-panel
            show-cta="true"
            title="login-modal.perks.title" />
        </div>
        <div
          ref="tabs"
          class="bg-paper-2 flex sticky z-1 md:hidden transition-shadow"
          :class="{ 'shadow-2': tabsStuck }"
          style="top: -1px">
          <button
            class="py-3 border-b-2 bg-transparent flex-1 typeset-10"
            :class="isLogin ? 'border-ink text-ink' : 'border-ink-1 text-ink-3'"
            @click="accountModalState = 'login'">
            {{ t("login-modal.tabs.login") }}
          </button>
          <button
            class="py-3 border-b-2 bg-transparent flex-1 typeset-10"
            :class="
              !isLogin ? 'border-ink text-ink' : 'border-ink-1 text-ink-3'
            "
            @click="accountModalState = 'register'">
            {{ t("login-modal.tabs.register") }}
          </button>
        </div>
        <div class="md:w-1/2 bg-paper-2 flex relative">
          <FadeTransition mode="out-in">
            <template v-if="isLogin">
              <login-panel :wedding-add-ons="weddingAddOns" />
            </template>
            <template v-else>
              <register-panel :wedding-add-ons="weddingAddOns" />
            </template>
          </FadeTransition>
        </div>
      </div>
    </template>
  </modal>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import FadeTransition from "../transitions/FadeTransition.vue";
import useUserNavStore from "../../stores/user-nav-store";
import Modal from "./Modal.vue";
import PerksPanel from "./PerksPanel.vue";
import LoginPanel from "./LoginPanel.vue";
import RegisterPanel from "./RegisterPanel.vue";

const userNavStore = useUserNavStore();

const { loginModalShowing, accountModalState } = storeToRefs(userNavStore);

const isLogin = computed(() => accountModalState.value === "login");

const tabs = ref(null);
const tabsStuck = ref(false);
const weddingAddOns = ref(false);

const tabsObserver = new IntersectionObserver(
  ([entry]) => {
    tabsStuck.value = !entry.isIntersecting;
  },
  {
    threshold: [1]
  }
);

const setWeddingAddOns = e => {
  if (e?.detail?.weddingAddOns) {
    weddingAddOns.value = e.detail.weddingAddOns;
  }
};

onMounted(() => {
  window.addEventListener("showLoginModal", setWeddingAddOns);
  tabsObserver.observe(tabs.value);
});

onBeforeUnmount(() => {
  tabsObserver.disconnect();
});
</script>
