<template>
  <div>
    <popover
      origin="top center"
      bordered
      menu-bg-class="bg-white"
      :menu-z-index="53">
      <template #target>
        <a
          href="#"
          class="inline-flex items-center h-5 px-3 hover:bg-paper-4 br-1 ab-160-control">
          <svg-icon
            name="user"
            class="mr-2"
            data-cy="click-account-from-navigation"
            width="16px"
            height="16px" />
          <span
            class="typeset-12 mr-2 relative top-px"
            data-cs-mask>
            {{ currentUser.first_name }}
          </span>
          <svg-icon
            name="downarrow-small-thin"
            width="10px"
            height="6px" />
        </a>
        <a
          href="#"
          class="inline-flex items-center justify-center hover:bg-paper-4 br-1 ab-160-variation user-nav-icon">
          <svg-icon
            name="user"
            width="24px"
            height="24px" />
        </a>
      </template>
      <template #body>
        <ul class="list-unstyled m-0">
          <li class="p-3 border-b border-paper-3">
            <p class="typeset-6">
              {{ currentUser.first_name }} {{ currentUser.last_name }}
            </p>
            <p>{{ currentUser.email }}</p>
            <div
              v-if="credit > 0"
              class="mt-3 px-3 py-2 bg-pink typeset-7 flex items-center justify-center">
              {{ creditLabel }}
            </div>
          </li>
          <li
            v-for="(path, index) in filteredPaths"
            :key="index">
            <a
              :href="path.url"
              :data-cy="`option-${index}`"
              class="block p-3 hover:bg-paper">
              {{ path.title }}
            </a>
          </li>
          <li>
            <a
              :href="logoutPath.url"
              data-cy="logout"
              class="block p-3 text-red hover:bg-paper"
              data-method="delete">
              {{ logoutPath.title }}
            </a>
          </li>
        </ul>
      </template>
    </popover>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import useUserNavStore from "../../stores/user-nav-store";
import Popover from "../shared/Popover.vue";
import SvgIcon from "../shared/SvgIcon.vue";

const userNavStore = useUserNavStore();

const { currentUser, credit, creditLabel, filteredPaths, logoutPath } =
  storeToRefs(userNavStore);
</script>
